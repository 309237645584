import { useTranslation } from 'react-i18next';
import { EmployeeDto } from 'entities/employee';
import { MachineryDto } from 'entities/machinery';
import { UnitDto } from 'entities/units';
import { Input } from 'shared/ui';

type Props = {
  employeesAll?: EmployeeDto[];
  machineryAll?: MachineryDto[];
  unitsAll?: UnitDto[];
};

export const TaskAssignmentForm: React.FC<Props> = ({
  employeesAll,
  machineryAll,
  unitsAll,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        select
        label="employee"
        name="employee_id"
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={employeesAll?.reduce((obj, item) => {
          obj[item.id] = item.employee_name;
          return obj;
        }, {} as { [id: number]: string })}
      />
      <Input
        select
        label="machinery"
        name="machinery_id"
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={machineryAll?.reduce((obj, item) => {
          obj[item.machinery_id] = item.model;
          return obj;
        }, {} as { [id: number]: string })}
      />
      <Input
        select
        label="unit"
        name="unit_id"
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={unitsAll?.reduce((obj, item) => {
          obj[item.unit_id] = item.model;
          return obj;
        }, {} as { [id: number]: string })}
      />
      <Input
        type="number"
        label="plannedFuelConsumption"
        name="planned_fuel_consumption"
        placeholder={`${t('plannedFuelConsumption')}`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps: { step: 0.1 },
        }}
      />
    </>
  );
};
