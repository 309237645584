import { baseApi, TagTypes } from 'shared/api';

import {
  TaskAssignmentAddResponse,
  TaskAssignmentChangeResponse,
  TaskAssignmentGetResponse,
  TaskAssignmentsGetResponse,
} from './types';

export const taskAssignmentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAssignmentsByTask: build.query<
      TaskAssignmentsGetResponse['task_assignments'],
      number
    >({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return {
          url: `api/get_assignments_by_task?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ assignment_id }) => ({
                type: TagTypes.TASK_ASSIGNMENT_TAG,
                id: assignment_id,
              })),
              TagTypes.TASK_ASSIGNMENT_TAG,
            ]
          : [TagTypes.TASK_ASSIGNMENT_TAG],
      transformResponse: (response: TaskAssignmentsGetResponse) => {
        return response.task_assignments;
      },
    }),
    getAssignmentById: build.query<
      TaskAssignmentGetResponse['assignment'],
      number
    >({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('assignment_id', id.toString());

        return {
          url: `api/get_assignment_by_id?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, arg) => [
        { type: TagTypes.TASK_ASSIGNMENT_TAG, id: arg },
      ],
      transformResponse: (response: TaskAssignmentGetResponse) => {
        return response.assignment;
      },
    }),
    addTaskAssignment: build.mutation<void, TaskAssignmentAddResponse>({
      query: ({
        task_id,
        employee_id,
        machinery_id,
        unit_id,
        planned_fuel_consumption,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('task_id', task_id.toString());
        searchParams.append('employee_id', employee_id.toString());
        searchParams.append('machinery_id', machinery_id.toString());
        searchParams.append('unit_id', unit_id.toString());
        searchParams.append(
          'planned_fuel_consumption',
          planned_fuel_consumption.toString(),
        );

        return {
          url: `api/add_task_assignment?${searchParams}`,
          method: 'POST',
        };
      },
      invalidatesTags: [TagTypes.TASK_ASSIGNMENT_TAG],
    }),
    changeTaskAssignment: build.mutation<void, TaskAssignmentChangeResponse>({
      query: ({
        assignment_id,
        employee_id,
        machinery_id,
        unit_id,
        planned_fuel_consumption,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('assignment_id', assignment_id.toString());
        searchParams.append('employee_id', employee_id.toString());
        searchParams.append('machinery_id', machinery_id.toString());
        searchParams.append('unit_id', unit_id.toString());
        searchParams.append(
          'planned_fuel_consumption',
          planned_fuel_consumption.toString(),
        );

        return {
          url: `api/change_task_assignment?${searchParams}`,
          method: 'PUT',
        };
      },
      invalidatesTags: [TagTypes.TASK_ASSIGNMENT_TAG],
    }),
    deleteTaskAssignment: build.mutation<void, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('assignment_id', id.toString());

        return {
          url: `api/delete_task_assignment?${searchParams}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [TagTypes.TASK_ASSIGNMENT_TAG],
    }),
  }),
});

export const {
  useGetAllAssignmentsByTaskQuery,
  useGetAssignmentByIdQuery,
  useAddTaskAssignmentMutation,
  useChangeTaskAssignmentMutation,
  useDeleteTaskAssignmentMutation,
} = taskAssignmentApi;
