import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as turf from '@turf/turf';
import * as varColor from 'app/styles/colors';
import { FarmlandsPolygons } from 'entities/farmlands';
import {
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  Polygon,
  Position,
} from 'geojson';
import { getBounds, getPolygonWithProp } from 'shared/lib';

import { getJoinFarmlandPolygons } from '../lib/getJoinFarmlandPolygons';

import { DrawType, MarkerDto } from './types';

type MapSliceState = {
  featureCollection: FeatureCollection<Geometry, GeoJsonProperties> | null;
  farmland_polygon: Position[];
  unaccounted_areas: Position[][];
  center: mapboxgl.LngLatLike;
  zoom: number;
  drawType: DrawType;
  bounds: mapboxgl.LngLatBoundsLike | null;
  markers: Array<MarkerDto>;
  currentMarkers: { [point_id: number]: mapboxgl.Marker } | null;
  isFitBounds: boolean;
};

const initialState: MapSliceState = {
  featureCollection: null,
  farmland_polygon: [],
  unaccounted_areas: [],
  center: [23.852931671475744, 51.954962438511274],
  zoom: 4,
  drawType: DrawType.farmland_polygon,
  bounds: null,
  markers: [],
  currentMarkers: null,
  isFitBounds: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCenter: (state, { payload }: PayloadAction<mapboxgl.LngLat>) => {
      const { lat, lng } = payload;
      state.center = [lng, lat];
    },
    setIsFitBounds: (state, { payload }: PayloadAction<boolean>) => {
      state.isFitBounds = payload;
    },
    setBounds: (
      state,
      { payload }: PayloadAction<mapboxgl.LngLatBoundsLike>,
    ) => {
      state.isFitBounds = true;
      state.bounds = payload;
    },
    setZoom: (state, { payload }: PayloadAction<number>) => {
      state.zoom = payload;
    },
    setDrawType: (state, { payload }: PayloadAction<DrawType>) => {
      state.drawType = payload;
    },
    setFeatureCollection: (
      state,
      {
        payload,
      }: PayloadAction<
        FeatureCollection<Geometry, GeoJsonProperties> | FarmlandsPolygons
      >,
    ) => {
      if (
        (payload as FeatureCollection<Geometry, GeoJsonProperties>).type ===
        'FeatureCollection'
      ) {
        const { features } = payload as FeatureCollection<
          Geometry,
          GeoJsonProperties
        >;

        const farmland_polygon_data = features.filter(
          ({ properties }) =>
            properties?.typeArea === DrawType.farmland_polygon,
        );
        const farmland_polygon =
          Array.isArray(farmland_polygon_data) && farmland_polygon_data.length
            ? (farmland_polygon_data[0].geometry as Polygon).coordinates[0]
            : [];
        const unaccounted_areas =
          Array.isArray(features) && features.length
            ? features
                .filter(
                  ({ properties }) =>
                    properties?.typeArea === DrawType.unaccounted_areas,
                )
                .map(({ geometry }) => (geometry as Polygon).coordinates[0])
            : [];

        const bounds = getBounds(farmland_polygon);
        state.bounds = bounds;
        state.featureCollection = payload as FeatureCollection<
          Geometry,
          GeoJsonProperties
        >;
        state.farmland_polygon = farmland_polygon;
        state.unaccounted_areas = unaccounted_areas;
      }

      if (
        (payload as FarmlandsPolygons).farmland_polygon &&
        (payload as FarmlandsPolygons).unaccounted_areas
      ) {
        const { farmland_polygon, unaccounted_areas, isJoinFarmlandPolygons } =
          payload as FarmlandsPolygons;

        if (isJoinFarmlandPolygons) {
          const features = getJoinFarmlandPolygons(
            payload as FarmlandsPolygons,
          );
          const featureCollection = turf.featureCollection(features);

          state.featureCollection = featureCollection;
        } else {
          const featuresUnaccounted = unaccounted_areas.map(
            (coordinates, index) => {
              return getPolygonWithProp(`unaccounted${index}`, coordinates, {
                typeArea: DrawType.unaccounted_areas,
                portColor: varColor.mediumAquamarine,
              });
            },
          );
          const featuresFarmland = () => {
            return getPolygonWithProp('farmland0', farmland_polygon, {
              typeArea: DrawType.farmland_polygon,
              portColor: varColor.darkBlue,
            });
          };
          const featureCollection = turf.featureCollection([
            ...featuresUnaccounted,
            featuresFarmland(),
          ]);
          state.featureCollection = featureCollection;
        }

        const bounds = getBounds(farmland_polygon);
        state.bounds = bounds;
        state.farmland_polygon = farmland_polygon;
        state.unaccounted_areas = unaccounted_areas;
      }
    },
    setMapMarkers: (state, { payload }: PayloadAction<Array<MarkerDto>>) => {
      state.markers = payload;
    },
    setCurrentMarker: (
      state,
      { payload }: PayloadAction<{ id: number; marker: mapboxgl.Marker }>,
    ) => {
      const { id, marker } = payload;
      state.currentMarkers = { ...state.currentMarkers, [id]: marker };
    },
    resetMapMarkers: (state) => {
      state.markers = initialState.markers;
    },
    resetFeatureCollection: (state) => {
      state.featureCollection = initialState.featureCollection;
      state.farmland_polygon = initialState.farmland_polygon;
      state.unaccounted_areas = initialState.unaccounted_areas;
      state.bounds = initialState.bounds;
      state.markers = initialState.markers;
    },
    clearMapData: (state) => {
      state.featureCollection = initialState.featureCollection;
      state.farmland_polygon = initialState.farmland_polygon;
      state.unaccounted_areas = initialState.unaccounted_areas;
      state.bounds = initialState.bounds;
      state.markers = initialState.markers;
      state.center = initialState.center;
      state.zoom = initialState.zoom;
      state.drawType = initialState.drawType;
    },
  },
});

export const selectMapSlice = (state: RootState) => state.map;

export const {
  setFeatureCollection,
  setCenter,
  setZoom,
  resetFeatureCollection,
  setDrawType,
  clearMapData,
  setMapMarkers,
  resetMapMarkers,
  setCurrentMarker,
  setIsFitBounds,
  setBounds,
} = mapSlice.actions;
