import { baseApi, TagTypes } from 'shared/api';

import {
  EmployeeAddRequest,
  EmployeeChangeRequest,
  EmployeeChangeStatusRequest,
  EmployeeChangeStatusResponse,
  EmployeeGetResponse,
  EmployeeIdResponse,
  EmployeePasswordChangeRequest,
  EmployeesGetResponse,
} from './types';

export const employeeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEmployee: build.query<EmployeeGetResponse['employee'], number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('employee_id', id.toString());

        return {
          url: `api/get_employee?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, arg) => [
        { type: TagTypes.EMPLOYEE_TAG, id: arg },
      ],
      transformResponse: (response: EmployeeGetResponse) => {
        return response.employee;
      },
    }),
    getAllEmployees: build.query<EmployeesGetResponse['employees'], void>({
      query: () => ({
        url: 'api/get_all_employees',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: TagTypes.EMPLOYEE_TAG,
                id,
              })),
              TagTypes.EMPLOYEE_TAG,
            ]
          : [TagTypes.EMPLOYEE_TAG],
      transformResponse: (response: EmployeesGetResponse) => {
        return response.employees;
      },
    }),
    addEmployee: build.mutation<number, EmployeeAddRequest>({
      query: (body) => ({
        url: 'api/employee_registration',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypes.EMPLOYEE_TAG],
      transformResponse: (response: EmployeeIdResponse) => {
        return response.employee_id;
      },
    }),
    changeEmployeeName: build.mutation<number, EmployeeChangeRequest>({
      query: ({ employee_id, employee_name }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('employee_id', employee_id.toString());
        searchParams.append('employee_name', employee_name);

        return {
          url: `api/change_employee?${searchParams}`,
          method: 'PUT',
        };
      },
      invalidatesTags: [TagTypes.EMPLOYEE_TAG],
      transformResponse: (response: EmployeeIdResponse) => {
        return response.employee_id;
      },
    }),
    changeEmployeePassword: build.mutation<
      number,
      EmployeePasswordChangeRequest
    >({
      query: (body) => {
        return {
          url: 'api/change_employee_password',
          method: 'PUT',
          body,
        };
      },
      transformResponse: (response: EmployeeIdResponse) => {
        return response.employee_id;
      },
    }),
    deleteEmployee: build.mutation<void, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();

        searchParams.append('employee_id', id.toString());

        return {
          url: `api/delete_employee?${searchParams}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [TagTypes.EMPLOYEE_TAG],
    }),
    changeEmployeeStatus: build.mutation<
      EmployeeChangeStatusResponse,
      EmployeeChangeStatusRequest
    >({
      query: (arg) => {
        return {
          url: 'api/change_employee_status',
          method: 'POST',
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useAddEmployeeMutation,
  useChangeEmployeeNameMutation,
  useChangeEmployeePasswordMutation,
  useDeleteEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetEmployeeQuery,
  useChangeEmployeeStatusMutation,
} = employeeApi;
